import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

type PropsType = {
  address: string,
};

function CopyAddress({ address }: PropsType) {
  const [showTooltip, setShowTooltip] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(address);
    if (!showTooltip) {
      setShowTooltip(true);

      setTimeout(() => {
        setShowTooltip(false);
      }, 1500);
    }
  };

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      TransitionComponent={Fade}
      title="Copied!"
      arrow
      open={showTooltip}
      onClose={() => setShowTooltip(false)}
      onOpen={() => setShowTooltip(true)}
    >
      <IconButton
        type="button"
        className="text-[10pt]"
        onClick={copyToClipboard}
        onKeyDown={(evt: { code: string }) => {
          if (['Space', 'Enter'].includes(evt.code)) {
            copyToClipboard();
          }
        }}
      >
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

export default CopyAddress;
