import React, {
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { Hex, isAddress } from 'viem';
import {
  useAccount,
  useWalletClient,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';
import useSWR from 'swr';
import { useSnackbar } from 'notistack';
import Divider from '@mui/material/Divider';
import { CheckIcon } from '@heroicons/react/24/outline';
import formatAddress from '../helpers/formatAddress';
import CopyAddress from '../components/CopyAddress';
import AddressSelect from '../components/AddressSelect';
// eslint-disable-next-line
import { signRecoveryWithGuardian, submitRecovery } from '../helpers/recoverFromGuardian';
import LoadingProgress from '../components/LoadingProgress';
import Alert from '../components/Alert';
import ConfirmModal from '../components/ConfirmModal';

const fetcher = async (url: string) => {
  const response = await fetch(url);
  return response.json();
};

type WalletGuardianData = {
  id: string,
  guardian: Hex,
  kernel: Hex,
  weight: number,
  chainId: number,
};

type KernelData = {
  id: string,
  kernel: Hex,
  owner: Hex,
  chainId: number,
  validatorAddress: Hex,
};

export default function RecoverAccount() {
  const [newOwnerAddress, setNewOwnerAddress] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [step, setStep] = useState<'ONE' | 'TWO'>('ONE');
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();
  const { switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();
  const [kernel, setKernel] = useState<WalletGuardianData | undefined>();
  const [loadingRecover, setLoadingRecover] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const kernelApiUrl = `https://kernel-api.zerodev.app/accounts/by-guardian?guardianAddress=${address}`;
  const { data } = useSWR<WalletGuardianData[]>(kernelApiUrl, fetcher);

  const kernelApiOwnerUrl = `https://kernel-api.zerodev.app/accounts/${kernel?.kernel}?chainId=${kernel?.chainId}`;
  const { data: ownerData } = useSWR<KernelData[]>(kernel ? kernelApiOwnerUrl : null, fetcher);

  useEffect(() => {
    if (switchNetwork && kernel?.chainId && (kernel.chainId !== chain?.id)) {
      enqueueSnackbar(`Awaiting switch to network ${kernel.chainId}`, { variant: 'info' });
      switchNetwork(kernel.chainId);
    }
  }, [kernel?.chainId]);

  const recoverWallet = async () => {
    setOpenConfirm(false);
    if (!kernel || !isAddress(newOwnerAddress)) {
      return;
    }

    try {
      if (address && walletClient) {
        setLoadingRecover(true);

        const alert = (message: string) => {
          enqueueSnackbar(message, {
            variant: 'info',
            persist: true,
          });
        };

        const { recoveryId, isContractWallet } = await signRecoveryWithGuardian(
          kernel.kernel,
          walletClient,
          newOwnerAddress,
          kernel.chainId,
          alert,
        );

        console.log('recover approved');
        await submitRecovery(
          recoveryId,
          kernel.chainId,
          walletClient,
          isContractWallet,
        );
      }
    } catch (e) {
      console.log('Error recovering wallet: ', e);
      setLoadingRecover(false);
      enqueueSnackbar('Error submitting recovery', { variant: 'error' });
      return;
    }
    setLoadingRecover(false);
    setStep('TWO');
    enqueueSnackbar('Wallet recovery complete', { variant: 'success', persist: true });
  };

  const recoverDisabled = () => {
    if (!kernel || !isAddress(newOwnerAddress)) {
      return true;
    }
    return false;
  };

  const guardianHasKernels = () => {
    if (data) {
      return data.length > 0;
    }
    return false;
  };

  if (!data) {
    return (
      <div className="h-96 flex flex-col items-center justify-center">
        <LoadingProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row w-full">
      <div className="md:w-1/3 w-full md:mx-4">
        <div className="relative flex flex-col px-6 gap-2 items-center isolate overflow-hidden bg-gray-900 py-6 shadow-custom-light rounded-3xl">
          <p className="mt-2 max-w-xl text-center font-bold text-md text-gray-300 flex items-center">
            Your connected guardian:
          </p>
          <div className="w-full text-gray-300">
            <Divider />
          </div>
          <div className="flex flex-row justify-between w-full items-center">
            <p>Address:</p>
            <p className="text-gray-300">
              {formatAddress(address)}
              <CopyAddress address={address ?? '0x'} />
            </p>
          </div>
        </div>
      </div>
      <div className="md:w-2/3 w-full md:mx-4 mt-8 md:mt-0">
        <div className="relative flex flex-col px-6 gap-2 items-center isolate overflow-hidden bg-gray-900 py-6 shadow-custom-light rounded-3xl">
          {!guardianHasKernels() && (
            <>
              <div className="flex flex-col justify-between gap-4 w-full">
                <p className="max-w-xl text-center font-bold text-md text-gray-300 flex items-center">
                  Kernel account:
                </p>
                <AddressSelect kernels={data} kernel={kernel} setKernel={setKernel} />
              </div>
              <div className="text-gray-300 mx-auto w-full mt-4">
                <Alert
                  text="The connected account does not appear to be a guardian for any Kernel accounts. Please double-check that you have been set up as a guardian."
                  heading="No Kernel Accounts Found"
                />
              </div>
            </>
          )}
          {guardianHasKernels() && step === 'ONE' && (
          <>
            <div className="flex flex-col justify-between gap-4 w-full">
              <p className="max-w-xl text-center font-bold text-md text-gray-300 flex items-center">
                Kernel account:
              </p>
              <AddressSelect kernels={data} kernel={kernel} setKernel={setKernel} />
            </div>
            <div className="w-full mt-4">
              <p className="max-w-xl mb-4 text-center font-bold text-md text-gray-300 flex items-center">
                Current signer address:
              </p>
              <TextField
                id="outlined-required"
                label="Address"
                value={ownerData?.[0]?.owner ?? ''}
                fullWidth
                disabled
              />
            </div>
            <div className="w-full mt-4">
              <p className="max-w-xl mb-4 text-center font-bold text-md text-gray-300 flex items-center">
                New signer address:
              </p>
              <TextField
                required
                id="outlined-required"
                label="Required"
                value={newOwnerAddress}
                fullWidth
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setNewOwnerAddress(event.target.value);
                }}
              />
            </div>
            <div className="self-end mt-4 min-w-[139px]">
              <LoadingButton
                disabled={recoverDisabled()}
                variant="outlined"
                onClick={() => setOpenConfirm(true)}
                loading={loadingRecover}
                loadingPosition="start"
                fullWidth
              >
                Recover
              </LoadingButton>
            </div>
          </>
          )}
          {guardianHasKernels() && step === 'TWO' && (
          <div className="w-full">
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-200">
              <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-base font-semibold leading-6 text-gray-300">
                Recovery successful!
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  You have successfully set the owner of
                  {' '}
                  <span className="font-bold">{formatAddress(kernel?.kernel)}</span>
                  {' '}
                  to
                  {' '}
                  <span className="font-bold">{formatAddress(newOwnerAddress)}</span>
                  . You may close this window now.
                </p>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
      <ConfirmModal
        open={openConfirm}
        setOpen={setOpenConfirm}
        kernelAddress={kernel?.kernel}
        newOwnerAddress={newOwnerAddress as Hex}
        oldOwnerAddress={ownerData?.[0]?.owner}
        submit={recoverWallet}
      />
    </div>
  );
}
