import { constants } from '@zerodev/sdk';
import { Hex, encodeFunctionData } from 'viem';
import { WeightedValidatorAbi } from './abis/WeightedValidatorAbi';

export async function getDisableuserOp() {
  const disableData: Hex = '0x';
  const encodedDisableData = await encodeDisable(disableData);

  return {
    target: constants.RECOVERY_VALIDATOR_ADDRESS,
    data: encodedDisableData,
  };
}

function encodeDisable(disableData: Hex = '0x'): Hex {
  return encodeFunctionData({
    abi: WeightedValidatorAbi,
    functionName: 'disable',
    args: [disableData],
  });
}
