import React from 'react';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';
import { Button } from '@mui/material';
import formatAddress from '../helpers/formatAddress';

export default function WalletConnectButton({ label }: { label?: string }) {
  const { open } = useWeb3Modal();
  const {
    address, isConnecting, isDisconnected, isConnected,
  } = useAccount();
  return (
    <div className="min-w-[139px]">
      <Button
        variant="outlined"
        onClick={() => open()}
        fullWidth
      >
        {isDisconnected && (label ?? 'Connect')}
        {isConnecting && 'Connecting...'}
        {isConnected && formatAddress(address)}
      </Button>
    </div>
  );
}
