import React from 'react';

import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import BackgroundGradient from './containers/BackgroundGradient';

function Template() {
  return (
    <div className="bg-gray-900 h-screen overflow-y-scroll">
      <Header />
      <div className="relative isolate pt-16 h-full">
        <BackgroundGradient>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 min-h-[calc(100vh-6rem)] h-full">
            <Outlet />
          </div>
        </BackgroundGradient>
      </div>
    </div>
  );
}

export default Template;
