import { type Hex } from '@alchemy/aa-core';
import {
  RecoveryProvider, convertWalletClientToAccountSigner,
} from '@zerodev/sdk';
import { WalletClient } from 'wagmi';
import { getProjectIdForChain } from './getProjectIdForChain';

type SignRecoveryWithGuardianResult = {
  recoveryId: string;
  isContractWallet: boolean;
};

export async function signRecoveryWithGuardian(
  kernelAddress: Hex,
  guardianSigner: WalletClient,
  newOwnerAddress: Hex,
  chainId: number,
  alert: (message: string) => void,
): Promise<SignRecoveryWithGuardianResult> {
  const zerodevProjectId = getProjectIdForChain(chainId);
  if (!zerodevProjectId) {
    throw Error('No project ID found for chain');
  }

  const recoveryProvider = await RecoveryProvider.init({
    projectId: zerodevProjectId,
    opts: {
      accountConfig: {
        accountAddress: kernelAddress,
      },
      paymasterConfig: {
        paymasterProvider: 'STACKUP',
        policy: 'VERIFYING_PAYMASTER',
      },
    },
  });
  const bytecode = await recoveryProvider.rpcClient.getContractCode(guardianSigner.account.address);
  const isContractWallet = bytecode !== '0x';
  const recoveryId = await recoveryProvider.initiateRecovery(newOwnerAddress);

  if (isContractWallet) {
    const [,isApproved] = await recoveryProvider.getApproval();
    console.log('isApproved', isApproved);

    if (isApproved) {
      alert('Approval already granted. Please continue to wait as we submit the recovery. Do not refresh page.');
      return { recoveryId, isContractWallet };
    }
    alert('Awaiting signature from guardian');
  } else {
    alert('Awaiting signature from guardian');
  }

  const guardianRecoveryProvider = await RecoveryProvider.init({
    projectId: zerodevProjectId,
    recoveryId,
    opts: {
      validatorConfig: {
        accountSigner: convertWalletClientToAccountSigner(guardianSigner as any),
        walletClient: guardianSigner as any,
      },
      paymasterConfig: {
        paymasterProvider: 'STACKUP',
        policy: 'VERIFYING_PAYMASTER',
      },
    },
  });

  if (isContractWallet) {
    try {
      await guardianRecoveryProvider.approveRecovery();
    } catch (e) {
      if (isTransactionNotFoundError(e)) {
        alert('Please continue to wait as we complete your transaction. Do not refresh page.');
        await checkApprovalUntilTrue(recoveryProvider);
      }
    }
  } else {
    await guardianRecoveryProvider.signRecovery();
  }

  return { recoveryId, isContractWallet };
}

export async function submitRecovery(
  recoveryId: string,
  chainId: number,
  guardianSigner: WalletClient,
  isContractWallet: boolean,
): Promise<`0x${string}`> {
  const zerodevProjectId = getProjectIdForChain(chainId);
  if (!zerodevProjectId) {
    throw Error('No project ID found for chain');
  }

  const submitterRecoveryProvider = await RecoveryProvider.init({
    projectId: zerodevProjectId,
    recoveryId,
    opts: {
      validatorConfig: {
        accountSigner: convertWalletClientToAccountSigner(guardianSigner as any),
        isSignerSmartContract: isContractWallet,
      },
      paymasterConfig: {
        paymasterProvider: 'STACKUP',
        policy: 'VERIFYING_PAYMASTER',
      },
    },
  });

  const result = await submitterRecoveryProvider.submitRecovery();

  return submitterRecoveryProvider.waitForUserOperationTransaction(
    result.hash as Hex,
  );
}

// Define a custom error type for TransactionNotFoundError
interface TransactionNotFoundError extends Error {
  name: 'TransactionNotFoundError';
  message: string;
}

// Function to check if an error is a TransactionNotFoundError
function isTransactionNotFoundError(error: any): error is TransactionNotFoundError {
  return error.name === 'TransactionNotFoundError';
}

async function checkApprovalUntilTrue(recoveryProvider: RecoveryProvider) {
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const [, approvalStatus] = await recoveryProvider.getApproval();
    if (approvalStatus) {
      console.log('Recovery approved');
      break;
    } else {
      console.log('Recovery not ready, waiting for 5 seconds...');
      // eslint-disable-next-line
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }
}
