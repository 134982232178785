import React from 'react';
import { useAccount } from 'wagmi';
import ConnectOptions from '../containers/ConnectOptions';
import RecoverAccount from '../containers/RecoverAccount';

function Home() {
  const { isConnected } = useAccount();

  return (
    <div className="flex flex-col h-full pb-24 pt-16">
      <div className="mx-auto max-w-sm flex flex-col justify-center items-center">
        <h3 className="mx-auto mb-6 text-center text-xl font-bold tracking-tight text-gray-300">
          Kernel Recovery Portal
        </h3>
        {!isConnected && (
          <div className="relative flex flex-col items-center isolate overflow-hidden bg-gray-900 px-6 py-6 shadow-custom-light rounded-3xl sm:px-16 text-center">
            <h2 className="mx-auto mt-6 text-center text-2xl font-bold text-gray-300">
              Connect a guardian
            </h2>
            <p className="mx-auto mt-2 text-center text-md text-gray-300">
              Choose how you want to connect your guardian.
            </p>
            <ConnectOptions />
          </div>
        )}
      </div>
      {isConnected && <RecoverAccount />}
    </div>
  );
}

export default Home;
