import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { WagmiConfig } from 'wagmi';
import {
  polygonMumbai, polygon, base, optimism, mainnet, sepolia, arbitrum,
} from 'viem/chains';
import { baseSepolia } from './helpers/chains/baseSepolia';
import { optimismSepolia } from './helpers/chains/optimismSepolia';
import { arbitrumSepolia } from './helpers/chains/arbitrumSepolia';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import './index.css';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '709907f584768426e8ea1f1390697b58';

// 2. Create wagmiConfig
const metadata = {
  name: 'Recovery Portal',
  description: 'Kernel Recovery Portal by ZeroDev',
  url: 'https://recovery.zerodev.app/',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const chains = [
  sepolia,
  polygon,
  base,
  baseSepolia,
  optimism,
  optimismSepolia,
  mainnet,
  polygonMumbai,
  arbitrum,
  arbitrumSepolia,
];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <WagmiConfig config={wagmiConfig}>
        <App />
      </WagmiConfig>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
