import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Hex } from 'viem';
import Chip from '@mui/material/Chip';
import formatAddress from '../helpers/formatAddress';
import { getChainById } from '../helpers/getChainById';

type WalletData = {
  id: string,
  guardian: Hex,
  kernel: Hex,
  weight: number,
  chainId: number,
};

type PropsType = {
  kernels?: WalletData[],
  setKernel: (address: WalletData) => void,
  kernel?: WalletData,
};

export default function AddressSelect({ kernels, setKernel, kernel }: PropsType) {
  const handleChange = (event: SelectChangeEvent) => {
    const selectedKernel = kernels?.find((k) => k.id === event.target.value);
    if (selectedKernel) {
      setKernel(selectedKernel);
    }
  };

  if (!kernels || kernels.length === 0) {
    return (
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="kernel-address-label">Address</InputLabel>
        <Select
          labelId="kernel-address-label"
          id="kernel-address-select"
          value="none"
          label="Address"
          onChange={handleChange}
          disabled
        >
          <MenuItem value="none">
            <div className="flex flex-row justify-between gap-5 w-full">
              <p className="mr-auto">None</p>
            </div>
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <InputLabel id="kernel-address-label">Address</InputLabel>
      <Select
        labelId="kernel-address-label"
        id="kernel-address-select"
        value={kernel?.id || ''}
        label="Address"
        onChange={handleChange}
      >
        {kernels.map((account) => (
          <MenuItem key={account.id} value={account.id}>
            <div className="flex flex-row justify-between gap-5 w-full">
              <p className="mr-auto">{formatAddress(account.kernel)}</p>
              <Chip label={getChainById(account.chainId)?.name} size="small" />
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
