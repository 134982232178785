import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export default function Modal({
  open,
  setOpen,
}: { open: boolean, setOpen: (open: boolean) => void }) {
  const cancelButtonRef = useRef(null);

  const setClose = () => {
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[51]" initialFocus={cancelButtonRef} onClose={setClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <h1 className="mt-2 text-lg font-bold tracking-tight text-gray-900 sm:text-4xl">
                    What&apos;s this?
                  </h1>
                  <p className="text-sm text-gray-900">
                    This is a portal for setting up a guardian for your account.
                    Your guardian can reset the primary key for your account, so
                    that if you lose your primary key or otherwise want to change
                    your key, you can ask the guardian to set a new key to control your account.
                  </p>
                  <h2 className="mt-4 text-md font-bold tracking-tight text-gray-900 sm:text-2xl">
                    Who should be my guardian?
                  </h2>
                  <p className="text-sm text-gray-900">
                    Someone or something you trust. For example, you can set another
                    wallet you own as your guardian, or a passkey device you own, or
                    a third-party such as the DApp that you are using your account with.
                    Keep in mind that your guardian can reset your key, so it&apos;s
                    paramount that you trust your guardian.
                  </p>
                  <h2 className="mt-4 text-md font-bold tracking-tight text-gray-900 sm:text-2xl">
                    How does my guardian recover my account?
                  </h2>
                  <p className="text-sm text-gray-900">
                    They can go to
                    {' '}
                    <a
                      href="https://recovery.zerodev.app/ "
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700 hover:underline"
                    >
                      https://recovery.zerodev.app
                    </a>
                  </p>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                    onClick={setClose}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
