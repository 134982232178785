/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Hex } from 'viem';
import useSWR from 'swr';
import Link from '@mui/material/Link';
import AddRecovery from '../containers/AddRecovery';
import RecoveryModal from '../components/RecoveryModal';
import DeleteRecovery from '../containers/DeleteRecovery';
import LoadingProgress from '../components/LoadingProgress';
import Modal from '../components/Modal';

const fetcher = async (url: string) => {
  const response = await fetch(url);
  return response.json();
};

type WalletData = {
  id: string,
  guardian: Hex,
  kernel: Hex,
  weight: number,
  chainId: number,
};

function RecoverySetup() {
  const [showModal, setShowModal] = useState(false);
  const [showWhatIsThis, setShowWhatIsThis] = useState(false);
  const { address } = useParams<{ address: string }>();

  const queryParams = new URLSearchParams(window.location.search);
  const chainId = queryParams.get('chainId') ?? undefined;
  const parentUrl = queryParams.get('parentUrl') ?? undefined;
  const parentUrlObj = parentUrl ? new URL(parentUrl) : { hostname: '' };

  const kernelApiUrl = `https://kernel-api.zerodev.app/accounts/${address}/guardians?chainId=${chainId}`;
  const { data: recoveryData } = useSWR<WalletData[]>(kernelApiUrl, fetcher);

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      if (event.origin !== parentUrl) {
        // Validate the message origin
        return;
      }

      const { data } = event;
      if (data.type === 'tx-submitted' && data.status === 'processing') {
        setShowModal(true);
      }

      if (data.type === 'tx-submitted' && data.status === 'done') {
        window.close();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  if (!recoveryData) {
    return (
      <div className="flex flex-col items-center justify-center h-full pb-16">
        <LoadingProgress />
      </div>
    );
  }

  return (
    <div className="flex items-center flex-col justify-center h-full">
      <RecoveryModal open={showModal} parentDomain={parentUrlObj.hostname} />
      <Modal open={showWhatIsThis} setOpen={setShowWhatIsThis} />
      {!recoveryData.length && (
        <AddRecovery
          address={address}
          parentUrl={parentUrl}
        />
      )}
      {recoveryData.length > 0 && (
        <DeleteRecovery address={address} />
      )}
      <div className="mt-2">
        <Link
          component="button"
          variant="body2"
          underline="hover"
          fontSize={14}
          margin={2}
          onClick={() => setShowWhatIsThis(true)}
        >
          What is this?
        </Link>
      </div>
    </div>
  );
}

export default RecoverySetup;
