import React from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Home from './pages/Home';
import RecoverySetup from './pages/RecoverySetup';
import Template from './Template';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Template />,
    errorElement: <Navigate to="/" replace />,
    children: [
      {
        index: true,
        path: '/recovery-setup/:address',
        element: <RecoverySetup />,
        id: 'recovery',
      },
      {
        index: true,
        path: '/',
        element: <Home />,
        id: 'home',
      },
    ],
  },
]);

function App() {
  return (
    <div className="overflow-hidden h-full">
      <SnackbarProvider maxSnack={3}>
        <RouterProvider router={router} />
      </SnackbarProvider>
    </div>
  );
}

export default App;
