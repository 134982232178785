import React from 'react';
import Chip from '@mui/material/Chip';

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { deleteRecovery } from '../helpers/popupMessaging';
import formatAddress from '../helpers/formatAddress';

type PropsType = {
  address?: string,
};

export default function DeleteRecovery({ address }: PropsType) {
  const queryParams = new URLSearchParams(window.location.search);
  const parentUrl = queryParams.get('parentUrl') ?? undefined;

  return (
    <div className="relative flex flex-col items-center isolate overflow-hidden bg-gray-900 px-6 py-6 shadow-custom-light rounded-3xl sm:px-16 text-center">
      <div className="flex flex-col gap-4 my-8">
        <div>
          <Tooltip
            TransitionComponent={Zoom}
            title={address}
            arrow
          >
            <Chip
              label={`Your Account: ${formatAddress(address)}`}
              variant="outlined"
              color="primary"
              className="font-semibold text-xl"
            />
          </Tooltip>
        </div>
        <p>Recovery is enabled for your account.</p>
        <div>
          <button
            type="button"
            onClick={() => deleteRecovery(parentUrl)}
            className="inline rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            Remove Guardian
          </button>
        </div>
      </div>
      <svg
        viewBox="0 0 1024 1024"
        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
        aria-hidden="true"
      >
        <circle cx={512} cy={512} r={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
        <defs>
          <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
            <stop stopColor="#00B4D8" />
            <stop offset={1} stopColor="#2B8AFC" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
}
