import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

type ConnectOptionsProps = {
  inputAddress: string,
  setInputAddress: (address: string) => void,
  guardianType: 'WALLET' | 'PASSKEY' | 'EMAIL',
};

export default function ConnectOptions2({
  inputAddress,
  setInputAddress,
  guardianType,
}: ConnectOptionsProps) {
  return (
    <div className="flex flex-col w-full">
      <div className="min-h-[130px]">
        {guardianType === 'WALLET' && (
        <div className="py-8 flex flex-col gap-2 align-middle justify-center">
          <div className="flex justify-center">
            <TextField
              value={inputAddress}
              id="outlined-basic"
              label="Guardian Address"
              variant="outlined"
              size="small"
              helperText="Enter the address of the guardian wallet"
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInputAddress(event.target.value);
              }}
            />
          </div>
        </div>
        )}
        {guardianType === 'PASSKEY' && (
        <div className="py-8 flex gap-12 align-middle justify-center">
          <Button variant="outlined" disabled>
            Coming soon
          </Button>
        </div>
        )}
        {guardianType === 'EMAIL' && (
        <div className="py-8 flex gap-12 align-middle justify-center">
          <Button variant="outlined" disabled>
            Coming soon
          </Button>
        </div>
        )}
      </div>
    </div>
  );
}
