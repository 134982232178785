import React, { useEffect, useState } from 'react';
import { isAddress } from 'viem';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import ConnectOptions from './ConnectOptions2';
import formatAddress from '../helpers/formatAddress';
import { enableRecovery } from '../helpers/popupMessaging';

type PropsType = {
  address?: string,
  parentUrl?: string,
};

export default function AddRecovery({
  address,
  parentUrl,
}: PropsType) {
  const [inputAddress, setInputAddress] = useState('');
  const [guardianType, setGuardianType] = useState<'WALLET' | 'PASSKEY' | 'EMAIL'>('WALLET');
  const [addRecoveryStep, setAddRecoveryStep] = useState<'ONE' | 'TWO'>('ONE');
  const queryParams = new URLSearchParams(window.location.search);
  const suggestedGuardianAddress = queryParams.get('suggestedGuardianAddress') ?? undefined;

  const nextEnabled = guardianType === 'WALLET' && isAddress(inputAddress);

  const handleChange = (event: React.SyntheticEvent, newValue: 'WALLET' | 'PASSKEY' | 'EMAIL') => {
    setGuardianType(newValue);
  };
  const setGuardian = async () => {
    if (!address || !inputAddress) {
      return;
    }
    if (isAddress(address) && isAddress(inputAddress)) {
      enableRecovery(address, inputAddress, parentUrl);
    }
  };

  useEffect(() => {
    if (suggestedGuardianAddress
      && isAddress(suggestedGuardianAddress)
      && suggestedGuardianAddress !== inputAddress
    ) {
      setInputAddress(suggestedGuardianAddress);
    }
  }, [suggestedGuardianAddress]);

  return (
    <div className="relative flex flex-col items-center isolate overflow-hidden bg-gray-900 px-6 py-6 shadow-custom-light rounded-3xl sm:px-16 text-center">
      <div className="flex flex-col">
        <h1 className="text-gray-300 font-bold text-2xl">Choose a guardian</h1>
        <p className="text-md font-normal text-gray-500">You can recover your account with your guardian</p>
        <div>
          <Tooltip
            TransitionComponent={Zoom}
            title={address}
            arrow
          >
            <Chip
              label={`Your Account: ${formatAddress(address)}`}
              variant="outlined"
              color="primary"
              className="font-semibold text-xl my-4"
            />
          </Tooltip>
        </div>
      </div>
      <div className="max-w-sm flex flex-col mt-2 w-full">
        <Divider variant="fullWidth" />
        <div className="flex flex-col min-h-[200px] items-center">
          <Tabs
            value={guardianType}
            onChange={handleChange}
            variant="fullWidth"
            className="w-full pb-2"
          >
            <Tab
              label="Wallet"
              value="WALLET"
            />
            <Tab
              label="Passkey"
              value="PASSKEY"
              disabled={addRecoveryStep === 'TWO'}
            />
            <Tab
              label="Email"
              value="EMAIL"
              disabled={addRecoveryStep === 'TWO'}
            />
          </Tabs>
          {addRecoveryStep === 'ONE' && (
            <ConnectOptions
              setInputAddress={setInputAddress}
              inputAddress={inputAddress}
              guardianType={guardianType}
            />
          )}
          {addRecoveryStep === 'TWO' && (
            <div className="flex flex-col w-full">
              <div className="flex flex-col gap-x-6 py-4 text-left">
                <p className="text-gray-400 text-md mb-2">
                  Confirm your guardian address.
                </p>
                <TextField
                  disabled
                  value={inputAddress}
                  inputProps={{ style: { fontSize: 14 } }}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </div>
              <div className="flex self-end">
                <Button
                  onClick={setGuardian}
                  variant="outlined"
                  size="small"
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </div>
        {addRecoveryStep === 'ONE' && (
        <div className="flex justify-end">
          <Button
            disabled={!nextEnabled}
            onClick={() => setAddRecoveryStep('TWO')}
          >
            Next
          </Button>
        </div>
        )}
        {addRecoveryStep === 'TWO' && (
        <div className="flex justify-start">
          <Button
            onClick={() => setAddRecoveryStep('ONE')}
          >
            Back
          </Button>
        </div>
        )}
      </div>
      <svg
        viewBox="0 0 1024 1024"
        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
        aria-hidden="true"
      >
        <circle cx={512} cy={512} r={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
        <defs>
          <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
            <stop stopColor="#00B4D8" />
            <stop offset={1} stopColor="#2B8AFC" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
}
