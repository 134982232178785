import React from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import WalletConnectButton from '../components/WalletConnectButton';

export default function ConnectOptions() {
  return (
    <div className="py-6 flex flex-col gap-6 w-full">
      <div className="w-full">
        <WalletConnectButton label="connect wallet" />
      </div>
      <Divider>or</Divider>
      <Button
        variant="outlined"
        disabled
        fullWidth
      >
        Passkey
      </Button>
      <Divider>or</Divider>
      <Button
        variant="outlined"
        disabled
        fullWidth
      >
        Email
      </Button>
    </div>
  );
}
