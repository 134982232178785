import {
  sepolia,
  polygon,
  optimism,
  arbitrum,
  base,
  mainnet,
  polygonMumbai,
  Chain,
} from 'viem/chains';
import { arbitrumSepolia } from './chains/arbitrumSepolia';
import { baseSepolia } from './chains/baseSepolia';
import { optimismSepolia } from './chains/optimismSepolia';

export function getChainById(id: number): Chain | null {
  switch (id) {
    case sepolia.id:
      return sepolia;

    case polygon.id:
      return polygon;

    case optimism.id:
      return optimism;

    case arbitrum.id:
      return arbitrum;

    case base.id:
      return base;

    case mainnet.id:
      return mainnet;

    case polygonMumbai.id:
      return polygonMumbai;

    case arbitrumSepolia.id:
      return arbitrumSepolia;

    case baseSepolia.id:
      return baseSepolia;

    case optimismSepolia.id:
      return optimismSepolia;

    default:
      return null;
  }
}
