import React from 'react';
import { useAccount } from 'wagmi';
import { useMatches } from 'react-router-dom';
import Logo from './ZeroLogo';
import WalletConnectButton from './WalletConnectButton';

export default function Header() {
  const { isConnected } = useAccount();
  const matches = useMatches();

  const [childRoute] = matches.slice(-1);
  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <div className="-m-1.5 p-1.5">
            <span className="sr-only">Kernel by ZeroDev</span>
            <Logo />
          </div>
        </div>
        <div className="flex flex-col md:flex-row lg:flex-1 lg:justify-end lg:items-center">
          {childRoute?.id !== 'recovery' && isConnected && <WalletConnectButton />}
        </div>
      </nav>
    </header>
  );
}
