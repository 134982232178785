import {
  encodeFunctionData,
  getFunctionSelector,
  Hex,
  encodeAbiParameters,
  parseAbiParameters,
  zeroAddress,
  http,
  createPublicClient,
} from 'viem';
import { polygonMumbai } from 'viem/chains';
import { KernelAccountAbi, constants } from '@zerodev/sdk';
import { WeightedValidatorAbi } from './abis/WeightedValidatorAbi';

export const publicClient = createPublicClient({
  transport: http('https://polygon-mumbai.infura.io/v3/f36f7f706a58477884ce6fe89165666c'),
  chain: polygonMumbai,
});

export const recoverySelector = getFunctionSelector(
  'doRecovery(address, bytes)',
);

function getEnableData(recoveryAddress: Hex): Hex {
  return encodeAbiParameters(
    parseAbiParameters(
      'address[] guardians, uint24[] weights, uint24 threshold, uint48 delay',
    ),
    [
      [recoveryAddress],
      [1],
      1,
      0, // delay in seconds
    ],
  );
}

function getEncodeSetExecution(recoveryAddress: Hex) {
  return encodeFunctionData({
    abi: KernelAccountAbi,
    functionName: 'setExecution',
    args: [
      recoverySelector,
      constants.RECOVERY_ACTION,
      constants.RECOVERY_VALIDATOR_ADDRESS,
      0,
      0,
      getEnableData(recoveryAddress),
    ],
  });
}

export async function getEnableRecoveryUserOp(kernelAccountAddress: Hex, recoveryAddress: Hex) {
  const encodedSetExecData = getEncodeSetExecution(recoveryAddress);
  return {
    target: kernelAccountAddress,
    data: encodedSetExecData,
  };
}

export async function getRecoveryData(kernelAccountAddress: Hex) {
  const recoveryConfig = await fetchRecoveryConfigFromContract(
    kernelAccountAddress,
  );
  return recoveryConfig;
}

interface WeightedGuardians {
  [guardian: Hex]: number;
}

async function fetchRecoveryConfigFromContract(
  kernelAccountAddress: Hex,
): Promise<any> {
  try {
    const [, threshold, delaySeconds, firstGuardian] = await publicClient.readContract({
      abi: WeightedValidatorAbi,
      address: constants.RECOVERY_VALIDATOR_ADDRESS,
      functionName: 'weightedStorage',
      args: [kernelAccountAddress],
    });

    const guardians: WeightedGuardians = {};

    let nextGuardian = firstGuardian;
    if (nextGuardian === zeroAddress) {
      return {
        threshold,
        delaySeconds,
        guardians,
      };
    }

    const sentinelValue = '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';
    while (
      nextGuardian.toLowerCase() !== sentinelValue.toLowerCase()
    ){
      // eslint-disable-next-line no-await-in-loop
      const guardianStorage = await publicClient.readContract({
        abi: WeightedValidatorAbi,
        address: constants.RECOVERY_VALIDATOR_ADDRESS,
        functionName: 'guardian',
        args: [nextGuardian, kernelAccountAddress],
      });
      // eslint-disable-next-line prefer-destructuring
      guardians[nextGuardian] = (guardianStorage as any)[0];
      // eslint-disable-next-line prefer-destructuring
      nextGuardian = (guardianStorage as any)[1];
    }
    return {
      threshold,
      delaySeconds,
      guardians,
    };
  } catch (error) {
    throw Error('Failed to fetch config from contract');
  }
}
