import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Hex } from 'viem';
import Button from '@mui/material/Button';
import formatAddress from '../helpers/formatAddress';

type PropsType = {
  open: boolean,
  setOpen: (open: boolean) => void,
  submit: () => void,
  kernelAddress?: Hex,
  newOwnerAddress?: Hex,
  oldOwnerAddress?: Hex,
};

export default function ConfirmModal({
  open,
  setOpen,
  kernelAddress,
  newOwnerAddress,
  oldOwnerAddress,
  submit,
}: PropsType) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center">
                    <CheckCircleIcon color="primary" fontSize="large" />
                  </div>
                  <div className="text-center">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-300">
                      Confirm Recovery
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-md text-gray-300">
                        You are about to set the owner of the
                        account
                        {' '}
                        <span className="font-bold">{formatAddress(kernelAddress)}</span>
                        {' '}
                        to
                        {' '}
                        <span className="font-bold">{formatAddress(newOwnerAddress)}</span>
                        {'. '}
                        The original owner
                        {' '}
                        <span className="font-bold">{formatAddress(oldOwnerAddress)}</span>
                        {' '}
                        will no longer have access to the account
                        after you complete the recovery.
                        You will still be the guardian of the account.
                      </p>
                      <br />
                      <p className="text-md text-gray-300">
                        Do you wish to proceed?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-6 grid grid-flow-row-dense grid-cols-2 gap-3">
                  <Button
                    type="button"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                    variant="outlined"
                  >
                    no
                  </Button>
                  <Button
                    type="button"
                    onClick={submit}
                    variant="contained"
                  >
                    yes
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
