import {
  sepolia,
  polygon,
  optimism,
  arbitrum,
  base,
  mainnet,
  polygonMumbai,
} from 'viem/chains';
import { arbitrumSepolia } from './chains/arbitrumSepolia';
import { baseSepolia } from './chains/baseSepolia';
import { optimismSepolia } from './chains/optimismSepolia';

const POLYGON_MUMBAI_PROJECT_ID = 'c5416975-9cc6-41e3-a43c-cd49f6610b8e';
const ARBITRUM_SEPOLIA_PROJECT_ID = '2d2d0381-ba44-4a5f-a613-1dfbb5c21fa8';
const BASE_SEPOLIA_PROJECT_ID = 'f828f1d6-f28a-4a36-b025-d62b89a869c4';
const OPTIMISM_SEPOLIA_PROJECT_ID = 'c146fa17-8920-4399-bd66-01f35e2a2e85';
const BASE_PROJECT_ID = '715995c5-e473-41a6-9462-09a27e71320f';
const OPTIMISM_PROJECT_ID = '87df521c-e780-42de-a5ac-64c878ccd00b';
const POLYGON_PROJECT_ID = '92ef80b9-d880-4695-a140-eeecd1c729da';
const ARBITRUM_PROJECT_ID = '5d2b66c5-e38d-4591-a21f-6a56a303edeb';
const MAINNET_PROJECT_ID = 'c8f07101-dda4-4236-a763-e0e84b5b6513';
const SEPOLIA_PROJECT_ID = 'ec9a8985-9972-42d4-9879-15e21e4fe3b6';

export function getProjectIdForChain(chainId: number): string | null {
  switch (chainId) {
    case polygonMumbai.id:
      return POLYGON_MUMBAI_PROJECT_ID;

    case arbitrumSepolia.id:
      return ARBITRUM_SEPOLIA_PROJECT_ID;

    case baseSepolia.id:
      return BASE_SEPOLIA_PROJECT_ID;

    case optimismSepolia.id:
      return OPTIMISM_SEPOLIA_PROJECT_ID;

    case base.id:
      return BASE_PROJECT_ID;

    case optimism.id:
      return OPTIMISM_PROJECT_ID;

    case polygon.id:
      return POLYGON_PROJECT_ID;

    case arbitrum.id:
      return ARBITRUM_PROJECT_ID;

    case mainnet.id:
      return MAINNET_PROJECT_ID;

    case sepolia.id:
      return SEPOLIA_PROJECT_ID;

    default:
      return null;
  }
}
