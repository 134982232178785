import { Hex } from 'viem';
import { getDisableuserOp } from './disableRecovery';
import { getEnableRecoveryUserOp } from './getRecoveryUserOp';

export async function deleteRecovery(parentUrl?: string) {
  if (!parentUrl) {
    return;
  }
  const userOp = await getDisableuserOp();
  window.opener.postMessage({ userOp, request: 'deleteRecovery' }, parentUrl);
}

export async function enableRecovery(
  kernelAddress: Hex,
  guardianAddress: Hex,
  parentUrl?: string,
) {
  if (!parentUrl) {
    return;
  }
  const userOp = await getEnableRecoveryUserOp(kernelAddress, guardianAddress);
  window.opener.postMessage({ userOp, request: 'enableRecovery' }, parentUrl);
}
